<template>
  <footer class="footer">
    <vue-qr :logoSrc="QRCodeLogo" :text="QRCodeUrl" :logoScale="0.2" :size="84" :margin="2"></vue-qr>
    <div>
      <p>
        <a @click="loading ? '' : chatContanct()">
          <img
            src="../../../../assets/images/servicer.png"
            style="width:25px;margin:0"
            alt=""
            v-if="!loading"
          />
          <i v-else class="el-icon-loading"></i>
          {{ $t("contactus") }}</a
        >
        <router-link :to="{ name: 'privacy' }">{{ $t("privacy") }}</router-link>
      </p>
      <p>
        2014-{{ year }} MeLinked {{ $t("companyright") }} 蜀ICP备15003211号-1
      </p>
    </div>
  </footer>
</template>
<script>
import VueQr from 'vue-qr';
export default {
  data() {
    return {
      year: "",
      QRCodeUrl :"https://m.melinked.com/",
      QRCodeLogo :"./favicon.png",
      loading: false
    };
  },
  components: {
    VueQr
  },
  created() {
    this.year = new Date().getFullYear();
  },
  methods: {
    // 点击联系我们，判断是否登录，没有登录则创建游客信息
    async chatContanct() {
      let userCatch = this.$store.getters.userInfo;
      if (userCatch.groupId) {
        this.$openIM({
          id: userCatch.groupId,
          displayName: userCatch.groupName,
          avatar: userCatch.groupHeader,
          isGroup: true
        });
      } else {
        this.loading = true;
        await this.handlerInitToursits();
        this.loading = false;
      }
    }
  }
};
</script>
<style lang="stylus">
.footer {
  height: 145px;
  text-align: center;
  display: flex;
  flex-flow: row;
  align-items: center;
  width: 100%;
  justify-content: center;
  background: #2c2c2c;
  a, p {
    color: #ffffff;
    margin: 0.5rem 0.5rem;
    font-size: 14px;
  }
p{
  display flex;
  flex-flow row
  justify-content center
}
  a {
    cursor: pointer;
    display flex
    align-items center;
  }

  img {
    margin: 0 0.5rem;
  }
}
</style>
