var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "footer",
    { staticClass: "footer" },
    [
      _c("vue-qr", {
        attrs: {
          logoSrc: _vm.QRCodeLogo,
          text: _vm.QRCodeUrl,
          logoScale: 0.2,
          size: 84,
          margin: 2
        }
      }),
      _c("div", [
        _c(
          "p",
          [
            _c(
              "a",
              {
                on: {
                  click: function($event) {
                    _vm.loading ? "" : _vm.chatContanct()
                  }
                }
              },
              [
                !_vm.loading
                  ? _c("img", {
                      staticStyle: { width: "25px", margin: "0" },
                      attrs: {
                        src: require("../../../../assets/images/servicer.png"),
                        alt: ""
                      }
                    })
                  : _c("i", { staticClass: "el-icon-loading" }),
                _vm._v("\n        " + _vm._s(_vm.$t("contactus")))
              ]
            ),
            _c("router-link", { attrs: { to: { name: "privacy" } } }, [
              _vm._v(_vm._s(_vm.$t("privacy")))
            ])
          ],
          1
        ),
        _c("p", [
          _vm._v(
            "\n      2014-" +
              _vm._s(_vm.year) +
              " MeLinked " +
              _vm._s(_vm.$t("companyright")) +
              " 蜀ICP备15003211号-1\n    "
          )
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }